
 .App {
  text-align: center;
  font-family: 'Cairo', sans-serif !important;
}
h1,h2,h3,h4,h5,h6,b {
  margin: 0px !important;
  font-size: unset !important;
}
*:focus {
  outline: unset !important;
}
.rmdp-input:focus {
  box-shadow:none !important;
  caret-color: transparent;
 
}
* {
  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari */
   -khtml-user-select: none !important; /* Konqueror HTML */
     -moz-user-select: none !important; /* Old versions of Firefox */
      -ms-user-select: none !important; /* Internet Explorer/Edge */
          user-select: none !important; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
