.main-div-kh {
  height: calc(100vh - 30px);
  overflow: auto;
  padding: 5px;
}

.table-res {
  
  border-collapse: separate;
border-spacing: 0 7px; /* Adds space between rows */
  width: 100%;
 margin-bottom: 20px;
}
.tr-res {
  border-radius: 2px;
}
.th-res,
.tr-res td {
  text-align: right;
  white-space: nowrap;
  padding: 3px 10px 3px 8px;
}

.span-btn-group {
  margin: 5px;
}
/* tr:nth-child(even) {
  background-color: #007bff17;
 
} */
.cell-header {
  display: none;
}
.show-button {
  display: none;
 
}
.Daily-delete {
      font-size: 14px;
      padding: 1px 7px;
      border-radius: 5px;
      margin: 4px;
      background-color: #e71616e3;
      color: white;
}
.Daily-Edit {
  font-size: 14px;
      padding: 1px 7px;
      border-radius: 5px;
      margin: 4px;
      background-color: #025c2be3;
      color: white;
}
@media screen and (max-width: 2000px) {
  .th-res {
    display: none;
    
  }
  .cell-header {
    display: block;
    font-weight: bold;
  }
  td {
      
      justify-content: space-between;
      direction: rtl;
      border-left: 3px solid #ff0e0e;
      padding-left: 5px;
      border-bottom: 0.1px solid #cdcdcd29;
  }
  .tr-res {
      position: relative;
      background-color: #ddeaff24;
      box-shadow: 2px 0px 0px 0px;;
  }
  .show-button {
      display: block;
      width: 10px;
      height: 10px;
      border: none;
      cursor: pointer;
      border-bottom: 2px solid;
      border-left: 2px solid;
      position: absolute;
      left: 47%;
      padding: 0px;
  }
  .show-button-down {
      transform: translate(0px ,-20px) rotate(315deg);
  }
  .show-button-up {
      transform: translate(0px ,-20px) rotate(135deg);
  }
  .hidden-info {
      display: none;
      transform: scale(0);
      transition: transform 0.5s ease-in-out;
  }
  .show-info {
    display: flex;
  }
 
}

