*{
    padding: 0;
    margin: 0;
}

.alert_container {
    background: #aedde5e3;
    width: 100%;
    position: fixed;
    z-index: 30000;
    top: 0%;
    left: 0%;
    height: 100vh;
}

.Alert_Kh {
    animation: popUpEntrada 0.5s;
    -webkit-animation: popUpEntrada 0.5s;
    margin: auto;
    background: #28818d;
    width: 100%;
    max-width: 320px;
    position: fixed;
    z-index: 3000;
    top: 40%;
    left: 50%;
    height: 100%;
    max-height: 140px;
    transform: translate(-50%,-50%);
    border-radius: 5px;
    padding-top: 2px;
    border: inset 2px rgb(0, 0, 0);
    word-wrap: break-word;
    text-align: center;
}

.Alert_content {
    display: block;
    padding: 0px;
    color: #e2f6eb;
    height: 84px;
    align-content: center;
}

.Alert_content div {
   
    padding: 0px 10px;
}
.Alert_content-div a{
    padding:5px;
    text-decoration:none;

}
.Alert_content-div a:hover {
    font-size:18px;
    cursor: pointer;
}
.kh-alert-btn {
    background: rgb(12 78 87);
    display: block;
    width: 100%;
    /* bottom: 10px; */
    /* position: absolute; */
    text-align: center;
    padding: 10px;
    color:#ffffffde
}
.kh-alert-btn #Alert_Ok{
    font-size: 15px;
}

.kh-alert-btn:hover{
    cursor: pointer;
    background: #333;
   
    border: inset 0.1px;
    transition: 0.2s;
    color:white;
   
}