.data-list {
    margin: 4px 10px;
}

.data-item {
    display: flex;
    
    text-align: right;
    direction: rtl;
    justify-content: space-between;
    min-width: 50vw;
    margin-bottom: 2px;
}
.CardWithPie-SubParent {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    padding: 5px
}
.CardWithPie-Parent {
    padding: 0px;
    padding-top: 5px;
    margin: 0px 5px 5px 10px;
    border: 1px solid #8080805c;
    background-color: #ffffff;
    /* #5995fd17; */
    border-radius: 10px;
    /* box-shadow: inset 0px 4px 3px; */
}
.CardWithBar-SubParent {
    
    align-items: center;
}