.Float-button {
    position: fixed;
    font-family: Arial, Helvetica, sans-serif;
    bottom: 15px;
    left: 25px;
    background-color: #007bffc4;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    transition: transform 0.3s ease-in;
  }
  .Float-button:hover{
    cursor: pointer;
  }
  .Float-button-rot {
    transform:rotateZ(45deg)
  }
  .button-min {
    background-color: red;
    z-index: 1;
    transform: translateY(-3.75rem);
    transition: transform 0.3s ease-in;
  
  }
  .button-plus {
    background-color: rgb(1, 245, 82);
    z-index: 1;
    transform: translateY(-7.4rem);
    transition: transform 0.3s ease-in;
  
  }
  .button-kh {

    animation: hideElement 0.3s forwards; /* 5s delay before hiding */
    transition: transform 0.3s ease-in;
    background-color: rgba(255, 0, 0, 0.301);
    z-index: 1;
  }
  .button-min-txt {
    position: fixed;
    bottom: 89px;
    left: 90px;
    font-size: 14px;
    z-index: 2;
    color: white;
    animation:visibleElement 0.8s ease-in-out forwards;
    background-color: #2d2d2dcf;
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: 0px 2px 7px 0px #701414;
}
  .button-plus-txt {
    position: fixed;
    bottom: 145px;
    left: 90px;
    font-size: 14px;
    z-index: 2;
    color: white;
    animation: visibleElement 0.8s ease-in-out forwards;
    background-color: #2d2d2dcf;
    padding: 3px 5px;
    border-radius: 5px;
    box-shadow: 0px 2px 7px 0px #23903e;
  }
  .button-txt {
    visibility: hidden;
  }
  @keyframes hideElement {
    0% {
      visibility: visible;
    }
    100% {
      visibility: hidden;
    }
  }
  @keyframes visibleElement {
    0% {
        opacity: 0.1;
      visibility: hidden;
    }
    30% {
        opacity: 0.3;
        visibility: visible;
      }
    50% {
        opacity: 0.5;
        visibility: visible;
      }
    100% {
        opacity: 1;
      visibility: visible;
    }
  }

