.R-subgroup {
    /* border: 1px solid #bdbdbd; */
    border-radius: 25px;
    padding: 15px;
    margin-bottom: 10px;
}
.Add-fieldset {
    border: 2px solid #80808057;
    padding: 10px;
    border-radius: 10px;
    position: relative;
}
.Add-fieldset legend{
   width: fit-content;
   font-size: 16px;
}
.Add-fieldset .R-subgroup:nth-child(odd) {
 background-color: #e7e7e747;
}
.Add-fieldset .R-subgroup:nth-child(even) {
    background-color: #c7ffd56b;
   }
.Add-fieldset-btn-add {
    border-radius: 10px;
    width: 100px;
    background-color: #007bff8f;
    color: white;
    border-color: #ffffff;
    height: 36px;
} 
.Add-fieldset-btn-remove {
    position: absolute;
    border-radius: 10px;
    width: 100px;
    background-color: #ff6d6d;
    color: white;
    border-color: #ffffff;
    height: 36px;
    left: 10px;    
} 