.GetAll-pages-kh {
  height: calc(100vh - 95px);
  overflow: auto;
  padding: 5px;
}
.MaterialPurchases-Sum {
    width: fit-content;
    padding: 8px;
    border-bottom: 1.5px solid #d9d4d4;
    margin-bottom: 5px;
    padding-bottom: 3px;
}
.form-addRepo {
    
    padding: 10px;
    margin-top: 15px;
    direction: rtl;
    text-align: right;
    height: calc(100vh - 70px);
    min-height: calc(100vh - 70px);
    overflow: auto;
}
.btn-Add {
  font-size: 17px;
  padding: 10px 25px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: 600;
}

.form-select {
    display: block;
    width: 100%;
    padding: .375rem .75rem .375rem 2.25rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: left .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
  }
  .form-label {
    margin-bottom: .1rem;
    color: rgba(0, 0, 0, 0.6);
  }
  .parent-Dmaterial {
    height: 100vh;
    width: 100%;
    position: absolute;
    background-color: #2e2e2e94;
    z-index: 10000;
  }
  .child-Dmaterial {
    position: absolute;
    width: 80%;
    max-height: 81%;
    background-color: white;
    top: 11%;
    left: 10%;
    padding: 15px;
    text-align: right;
    direction: rtl;
    border-radius: 10px;
    overflow-y: auto;
    box-shadow: -3px 3px 7px 2px;
    font-size: 14px;
  }
  .child-Dmaterial .Details-kh {
    margin: 10px;
  }
  .btn-delete {
    min-width: 150px;
    margin: 45px auto auto;
    display: block;
    background-color:#b51919;
  }
  .btn-delete:hover {
    cursor: pointer;
    border: 2px solid #072507;
    transition: 0.3s;
  }
  .child-Dmaterial span {
    font-weight:700 ;
  }
  .close-kh {
    left: 20px;
    position: absolute;
    font-size: 18px;
  }
  .close-kh:hover {
    cursor: pointer;
    transform: scale(1.2);
    transition: 0.3s;
  }
.Note-kh {
  border: 1px solid #415353;
  padding: 5px;
  border-radius: 8px;
  background-color: #89898975;
  /* color: black; */
  height: fit-content;
  min-height: 60px;
  /* box-shadow: -1px 1px 1px 1px; */
  width: 100%;
  margin: 5px 1px !important;
}