.SearchBlock-kh {
    width:40%;
    padding: 5px;
    background-color: rgb(0 173 0);
    color: white;
    border-radius: 3px;
}
.SearchBlock-kh:hover {
    cursor: pointer;
    border-radius: 10px;
    background-color: rgb(2, 131, 2);
}
.delete-kh {
   
}
.search-kh {
    float: left;
}
.search-checkBox {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    transform: translateY(4px);
}