*{
  margin: 0;
padding: 0;
}
hr {
    margin: 0px !important;
}
.UserHeader-nav {
    background-color: transparent;
    padding-top: 0px;
    position: relative;
    width: 100%;
    z-index: 1010;
}
.UserHeader-ul {
    display: block;
    list-style-type: none;
   
}
.UserHeader-ul a {
    text-decoration: none;
    color: rgb(165, 161, 161);
}
.UserHeader-ul a:hover>span {
    
    color: rgb(255, 255, 255) !important;
    transform: scale(1.02);
    
}
.UserHeader-ul li {
    display: inline;
    margin: 0px 7px 0px 20px;
    font-size: 16px;
}
.UserHeader-ul span {
    display: inline-block;
    transition: transform 0.01s ease-in;
}


.UserHeader-content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding:10px 11px 3px;
    align-items: center;
    color: white;
    background-color: #007bff;
}
.UserCarousel-kh {
    background-color: rgb(88, 75, 75);
    height: 50em;
    width: 100%;
}
.UserHeader-ul>span {
    display: none;
}
.User-size-x-2 {
    display: none;
}

.kh-typewriter h1 {
     /* Ensures the content is not revealed until the animation */
    /* border-left: 0.15em solid rgb(199, 72, 72); The typwriter cursor */
    /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 50px;
    letter-spacing: normal;
    font-family: "Gulzar", serif;
  
  }
  .kh-typewriter {
   
   height: auto;
    margin: auto;
    
  }
 .UserHeader-Icon {
  width: 25px;
  padding: 3px 10px;
  font-size: 20px;
 }
 .Page-Title-kh {
    display: block;
    position: absolute;
    margin: 10px 20px;
    font-size: 18px;
    right: 36px;
    top: 6px;
 }
  /*
    Round Range Slider element
  */
  .UserHeader-img-kh {
    width: 167px;
    margin: 10px;
    margin-bottom: 20px;
    transform: translateX(-8px) rotate(347deg);
  }
@media only screen and (max-width: 2008px){

    /* .UserHeader-Sidebar{
        height: 100px;
        width: 100px;
        background-color:transparent;
        border-radius: 5px;
        position: relative;
        top: 0;
    } */
    .UserHeader-ul>span {
        display: block;
        position: absolute;
        top: 22px;
        left: 15px;
        font-size: 30px;
        font-weight: 600;
    }
    .UserHeader-ul div{
        padding: 7px;
    }
   
    .UserHeader-ul>span:hover {
        cursor: pointer;
        font-weight: 650;
        transform: scale(1.12);
    }
   
    
    .UserHeader-ul {
        position: fixed;
        right: 0px;
        top: 0;
        height: 110vh;
        min-height: 110vh;
        border: 2px solid #8080805e;
        padding-top: 10px;
        background-color: white;
        text-align: right;
        box-shadow: -2px 4px 12px 0px;
        transition: all 1s linear;
        z-index: 2;
        overflow-y: auto;
        margin-bottom: 10px;
        padding-bottom: 40px;
        }
    .UserHeader-ul li {
        display: flex;
        flex-direction: column-reverse;
        width: 12rem;
        
    }
    .UserHeader-ul span {
        text-decoration: none;
        color: rgb(0, 0, 0) ;
    }
    .UserHeader-ul a:hover>span {
        
        color: rgb(5, 22, 75) !important;
        transform: scale(1.02);
        
    }

    .User-size-x-2 {
        font-size: 1.5em;
        margin-right: 10px;
        color: #fffafa;
        display: block;
    }
   
    .User-size-x-2:hover {
        color: #dddbdba1;
        cursor: pointer;
    }
    .UserHeader-Active {
        transform: translateX(0rem);
		transition: transform 300ms ease-in;
    }
    .UserHeader-Unactive {
        transform: translateX(18rem);
		transition: transform 300ms ease-out;
    }

    @keyframes SidebarAnimation {
        0%{width: 0px;}
        25%{width: calc(0.25*240px);}
        50%{width: calc(0.5*240px);}
        75%{width: calc(0.75*240px);}
        100%{width: fit-content}
    }
}