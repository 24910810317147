   .Transaction-kh{
    padding: 3px;
    border-left: 3px solid rgb(252, 0, 0);
    background-color: #ffffffd9;
    font-size: 12px;
    margin-bottom:4px ;
   }
   .Incoming-kh {
    border-left: 3px solid rgb(0, 167, 50) !important;
   }
 .Transaction-kh table {
    width: 100%;
    direction: rtl;
    text-align: left;
 }
 .Transaction-kh table tr>:first-child {
    text-align: right;
 }

 .parent-kh {
    position: relative;
    width: 100%;
    height: 40px;
    direction: rtl;
  }
  
  .parent-kh div {
    margin: 2px;
    position: absolute;
   
  }
  
  
  .parent-kh div:nth-child(1) {
    top: 0;
    left: 0;
  }
  
  .parent-kh div:nth-child(2) {
    top: 0;
    right: 0;
  }
  
  .parent-kh div:nth-child(3) {
    bottom: 0;
    left: 0;
  }
  
  .parent-kh div:nth-child(4) {
    bottom: 0;
    right: 0;
  }
  