.credit-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);    
    margin: 15px;
}

.category-name {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    direction: rtl;
    text-align: right;
    margin: 10px;
}

.category-image img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    object-fit: cover;
}
.Listcategory-kh {
    direction: ltr;
    text-align: center;    
}
.Listcategory-kh h3 {
    font-size: 18px !important;
}