.table-container {
    padding: 10px;
    overflow: auto;
    height:calc(100vh - 50px);
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0; 
    text-align: right;
}
  
  .custom-table th, .custom-table td {
    border: 1px solid #ddd;
    padding: 8px 3px;
    direction: rtl;
  }
  
  .custom-table th {
    background-color: #f2f2f2;
    color: black;
  }
  
  .custom-table th, .custom-table td {
    border: 2px solid #ccc; 
  }
  .custom-table tr:nth-child(even) {
    background-color: #f9f9f9; 
  }
  .custom-table tr td:first-child,
  .custom-table tr th:first-child  {
    text-align: center;
  }
  .payment-delete {
    background-Color: #000;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
  }
  .payment-delete:hover {
    cursor: pointer;
    transform: scale(1.05);
  }