.data-list-Boxes {
    margin: 10px auto 10px auto;
    width: 65%;
}
.table-Boxes-parent{
    margin: 0 25px;
}
.custom-table-Boxes {    
    width: 100%;
    /* border-collapse: collapse; */
    margin: 20px 0;
    padding-bottom: 5px;  
}
.custom-table-Boxes tr td:first-child, .custom-table-Boxes tr th:first-child {
    text-align: center;
}
.custom-table-Boxes td {
    border:none;
    border-bottom: 1px solid #80808036;
    padding: 5px 0;
    font-size: 13px;
}
.custom-table-Boxes th {
    border:none;
    border-bottom: 1px solid #80808036;
    padding: 5px 0;
    font-weight: 700;
    font-size: 12px;
}

