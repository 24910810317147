.date-picker-bar {
    background-color: #007bff; /* Blue bar color */
    color: white;
    text-align: center;
    font-size: 18px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
  }
  .date-picker-bar-textbox {
    border: 1px solid #007bff;
    border-radius: 5px;
   
    background-color: #007bff;
    text-align: center;
  }
  
  .date-picker-bar .btn {
    width: 15%;
    padding: 10px;
  }
 